/**
 * @file: Describe the file
 * @author:
 * @Date: 2019-02-11 17:54:15
 * @Last Modified by: luocixing
 * @Last Modified time: 2020-11-25 18:16:10
 */
import router from './router';

export default router;
