import baiduBce from 'bce-sdk-js/baidubce-sdk.bundle.min.js';
import { getScanAuthorization } from '@/api/';

const PART_SIZE = 16 * 1024 * 1024;
function getBosMultiTasks(file, uploadId, bucketName, key, chunksize = PART_SIZE) {
    let leftSize = file.size;
    let offset = 0;
    let partNumber = 1;
    let tasks = [];
    while (leftSize > 0) {
        let partSize = Math.min(leftSize, chunksize);
        tasks.push({
            file: file,
            uploadId: uploadId,
            bucketName: bucketName,
            key: key,
            partNumber: partNumber,
            partSize: partSize,
            start: offset,
            end: offset + partSize,
            loaded: 0
        });

        leftSize -= partSize;
        offset += partSize;
        partNumber += 1;
    }
    return tasks;
}

function uploadPartFile(state, client, task, onPartFileResolve) {
    return new Promise((resolve, reject) => {
        const blob = task.file.slice(task.start, task.end);
        onPartFileResolve(blob, task.partNumber);

        let time = 0;
        const handle = () => {
            client.uploadPartFromBlob(task.bucketName, task.key, task.uploadId, task.partNumber, task.partSize, blob)
            .then(function (res) {
                ++state.loaded;
                resolve(res);
            })
            .catch(function (err) {
                if (time < 3) {
                    time++;
                    handle();
                } else {
                    reject(err);
                }
            });
        };

        handle();
    });
}

export default class BosUploader {

    constructor() {
        this.bucket = 'sec-scan-faw-vk';
        this.client = null;
    }

    async uploadLargeFile({ blob, key, fileName, onProgress, onPartFileResolve }) {

        await this.makeClient();

        // 保存到bos时的key，您可更改，默认以文件名作为key
        const ext = fileName.split(/\./g).pop();
        let mimeType = baiduBce.MimeType.guess(ext);

        if (/^text\//.test(mimeType)) {
            mimeType += '; charset=UTF-8';
        }
        const options = {
            'Content-Type': mimeType,
            'Content-Length': blob.size
        };

        const res = await this.client.initiateMultipartUpload(this.bucket, key, options);
        const { uploadId } = res.body;
        const tasks = getBosMultiTasks(blob, uploadId, this.bucket, key);

        this.client.on('progress', (e, params) => {
            let loaded = 0;
            let index = 0;
            if (e.lengthComputable) {
                index = params.args.params.partNumber;
                if (index == null) {
                    return;
                }
                for (let i in tasks) {
                    if (Number(i) === index - 1) {
                        tasks[i].loaded = e.loaded;
                    }
                    loaded = loaded + tasks[i].loaded;
                }
                onProgress(loaded / blob.size);
            }
        });

        const state = {
            lengthComputable: true,
            loaded: 0,
            total: tasks.length
        };

        let promises = [];
        let responses = [];
        for (let i = 0; i < tasks.length; i++) {
            const promise = uploadPartFile(state, this.client, tasks[i], onPartFileResolve);
            promises.push(promise);
            if (promises.length === 5 || i === tasks.length - 1) {
                const results = await Promise.all(promises);
                responses.push(...results);
                promises = [];
            }
        }
        const partList = responses.map((response, index) => ({
            partNumber: index + 1,
            eTag: response.http_headers.etag
        }));
        await this.client.completeMultipartUpload(this.bucket, key, uploadId, partList);
        return key;
    }

    async makeClient() {
        const config = await this.getUploadConfig();
        this.client = new baiduBce.BosClient(config);
        return this.client;
    }

    async getUploadConfig() {
        const res = await getScanAuthorization();
        const data = res.data.data;
        const bosConfig = {
            credentials: {
                ak: data.ak, // STS服务器下发的临时ak
                sk: data.sk // STS服务器下发的临时sk
            },
            sessionToken: data.sessionToken, // STS服务器下发的sessionToken
            endpoint: data.endpoint // bos服务地区域名地址
        };
        return bosConfig;
    }
}