
import {partAxios, jsonRequestV2, securityAxiosV1} from '@/config/axios';

// 获取登录信息
export const getLogin = params => jsonRequestV2('v2', '/current_user/get_info', params);

// 获取Bos临时STS
export const getScanAuthorization = () => partAxios('v1', '/security-scan/bce/authorization');

// FIXME: DONE
export const queryIssuedResult = params => jsonRequestV2('v2', '/task/getissuedresult', params);
export const queryDevIssuedResult = params => jsonRequestV2('v2', '/task/getdeviceresult', params);
export const updateTaskStatus = params => jsonRequestV2('v2', '/task/setstatus', params);

// 权限
export const getProductPower = params => jsonRequestV2('v2', '/current_user/get_permissions', params);

// 获取产品信息
export const getProductInfo = params => jsonRequestV2('v2', '/product/get_ota_config', params);

// 安全检测
// 获取任务列表
export const getSecurityList = params =>
securityAxiosV1('get', '/security-scan/private/type/{type}/tasks', params);
// 创建任务
export const createSecurityTask = params =>
securityAxiosV1('post', '/security-scan/private/type/{type}/task', params);
// 删除任务
export const delSecurityTask = params =>
securityAxiosV1('delete', '/security-scan/private/task/{task_id}', params);
// 获取产品线总览
export const getSecuritySummary = params =>
securityAxiosV1('get', '/security-scan/private/type/{type}/summary', params);

// 获取csrf token
export const getCsrfToken = params => jsonRequestV2('v2', '/auth/get_csrf_token', params);
// 登录
export const login = params => jsonRequestV2('v2', '/auth/login', params);
// 退出登录
export const logout = params => jsonRequestV2('v2', '/auth/logout', params);
