<template>
    <el-container id="app">
        <el-header>
            <el-row
                type="flex"
                align="middle"
                class="header"
                justify="space-between"
            >
                <img src="@/assets/img/head_logo.svg" class="logo" />
                <el-button
                    v-if="$route.name !== 'login'"
                    type="text"
                    style="margin-right: 20px"
                    @click="logout"
                >退出登录</el-button>
            </el-row>
        </el-header>
        <el-main class="main-content">
            <router-view></router-view>
        </el-main>
        <el-footer class="footer">
            <span>技术支持电话：0431-85990175</span>
            <a href="https://beian.miit.gov.cn">京ICP证030173号-176</a>
        </el-footer>
    </el-container>
</template>

<script>
import { logout } from './api';
export default {
    name: 'app',
    methods: {
        async logout() {
            await logout();
            this.$router.replace({ name: 'login' });
        }
    }
}
</script>

<style lang="scss" scoped>
html {
    font-family: "MicrosoftYaHei";
    font-family: "微软雅黑";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    width: 100%;
    height: 100%;
}
body {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "微软雅黑", "Microsoft YaHei", Arial, sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
}

* {
    padding: 0;
    margin: 0;
}

#app {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.header {
    height: 60px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
}

.main-content {
    overflow: scroll;
}

.footer {
    width: 100%;
    height: 40px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.footer a, .footer span {
    font-size: 12px;
    color: #BBB;
    text-decoration: none;
}

.footer a:hover,
.footer span:hover {
    color: #222;
}

.logo {
    height: 40px;
    margin-left: 20px;
}
</style>
