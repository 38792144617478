/**
 * @file:
 * @author: sunchao(sunchao15@baidu.com)
 * @Date: 2019-10-08 09:53:09
 * @LastEditors: sunchao
 * @LastEditTime: 2020-01-03 14:47:28
 */

<template>
  <el-popover
    v-if="content && content.length > remains"
    class="popover"
    :placement="placement"
    :width="width"
    :trigger="trigger"
    :content="content"
    button-title
    :remains="remains"
    :button-style="buttonStyle">
    <el-button slot="reference" type="text" :style="buttonStyle">
      {{buttonTitle || `${content.slice(0, remains)}...`}}
    </el-button>
  </el-popover>
  <span v-else>{{content}}</span>
</template>

<script>
export default {
  name: 'my-popover',
  props: {
    placement: {
      type: String,
      default: () => 'top-start'
    },
    width: {
      type: [String, Number],
      default: () => 'auto'
    },
    trigger: {
      type: String,
      default: () => 'hover'
    },
    buttonTitle: {
      type: String,
      default: () => ''
    },
    remains: {
      type: Number,
      required: true
    },
    content: {
      type: [String,Number],
      required: true
    },
    buttonStyle: {
      type: Object,
      default: () => ({color:'#606266', padding: 0})
    }
  },
  data () {
    return {
      cont: this.content
    }
  }
}
</script>
<style lang="scss">
.popover {
  word-break: break-all!important;
}
</style>
