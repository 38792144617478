<template>
    <div class="ota-uploader">
        <el-button v-if="canUpload" size="small" type="primary" @click="upload">
            <slot>点击上传</slot>
        </el-button>
        <div @mouseenter="isShowX = true" @mouseleave="isShowX = false" class="show-file" v-else>
            <span class="name" @click="upload">{{file.name}}</span>
            <span :class="{show: isShowX}" class="delete-file" @click="deleteFile">x</span>
        </div>
        <input @change="handleChange($event)" ref="input" type="file" :accept="accept">
    </div>
</template>

<script>
export default {
    props: {
        file: [Object, File],
        accept: String,
        size: Number
    },
    computed: {
        canUpload() {
            return this.file && this.file.name === undefined
        }
    },
    data() {
        return {
            isShowX: false
        }
    },
    methods: {
        upload() {
            this.$refs.input.click();
        },
        handleChange(e) {
            let file = e.target.files[0];
            e.target.value = '';
            if (!this.validator(file)) {
                this.deleteFile();
                return;
            }
            this.$emit('update:file', file);
            this.$emit('change', file);
        },
        deleteFile() {
            this.$emit('update:file', {})
        },
        validator(file) {
            let flag = true;
            const { size } = this;
            if (size) {
                const fileSize = file.size / 1000;
                if (fileSize > size) {
                    this.$message.error(`上传文件不能大于${size}kb`);
                    flag = false;
                }
            }
            return flag;
        }
    }
}
</script>

<style lang="scss" scoped>
    .ota-uploader {
        input {
            display: none;
        }
        .show-file {
            color: #409eff;
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            &:hover {
                background-color: #e6effb;
                cursor: pointer;
            }
            .name {
                display: inline-block;
                width: 100px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            .delete-file {
                margin-left: 8px;
                visibility: hidden;
                &:hover {
                    cursor: pointer;
                }
            }
            .show {
                visibility: visible;
            }
        }
    }
</style>