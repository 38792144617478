/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-26 14:42:29
 * @LastEditTime: 2020-03-02 20:49:28
 * @LastEditors: chenruijing
 */

/**
 * @file: Describe the file
 * @author: sunchao15@baidu.com
 * @Date: 2019-03-14 16:30:59
 * @Last Modified by: sunchao15
 * @Last Modified time: 2019-03-14 16:51:33
 */

import qs from 'qs';

import {
    queryIssuedResult,
    getAllDevidGroupList,
    queryDevIssuedResult,
    updateTaskStatus
} from '@/api/';

/**
 * 该方法是route方法
 */
export class getRouter {
    constructor() {
        // this.url = window.location.href;
        this.routerPath = window.location.pathname;
        // this.param = this.$route.params;
    }
    params() {
        const params = this.routerPath.split('/');
        return params;
    }
    lastPath() {
        const params = this.params();
        const tempLast = params.pop();
        const lastPath = tempLast ? tempLast : params.pop();
        return lastPath;
    }
}

export const clearAllSession = () => {
    sessionStorage.removeItem('cur_token');
    sessionStorage.removeItem('cur_product');
    sessionStorage.removeItem('cur_control_item');
    sessionStorage.removeItem('cur_task_view');
    sessionStorage.removeItem('cur_task');
    sessionStorage.removeItem('dstPackage');
    sessionStorage.removeItem('srcPackages');
    sessionStorage.removeItem('cur_page_index');
    sessionStorage.removeItem('cur_devid_group_page_index');
    sessionStorage.removeItem('cur_devid_group');
    sessionStorage.removeItem('all_devid_group');
};

export function getXToken() {
    if (sessionStorage.getItem('x-token') === null) {
        return '';
    }

    return sessionStorage.getItem('x-token');
}

export function setXToken(token) {
    if (token === '' || token === null) {
        sessionStorage.removeItem('x-token');
        return;
    }

    sessionStorage.setItem('x-token', token);
}

export function getCurToken() {
    if (sessionStorage.getItem('cur_token') === null) {
        return '';
    }

    return sessionStorage.getItem('cur_token');
}

export function setCurToken(token) {
    if (token === '' || token === null) {
        sessionStorage.removeItem('cur_token');
        return;
    }

    sessionStorage.setItem('cur_token', token);
}

/**
 * @name: applyDeepClone
 * @description: 该方法为深拷贝
 * @param {origin, ...target}
 * @return:
 */
const deepClone = function (origin, target) {
    for (let i in target) {
        if (typeof (i) !== 'object') {
            origin[i] = target[i];
        }
        else {
            deepClone(origin[i], target[i]);
        }
    }
};

export const applyDeepClone = function (origin, ...target) {
    target.forEach(item => {
        deepClone(origin, item);
    });
    return origin;
};

/**
 * @description: 浮点数精确计算专用
 * @param {type}
 * @return:
 */

export const strip = (num, precision = 12) => {
    return +parseFloat(num.toPrecision(precision));
};

/**
 * @description: 计算计划升级数, 该数值会改变 不再用闭包
 * @param {type}
 * @return:
 */

export const calcPlanDev = async ({
        curTask,
        pid,
        tid
    }, params) => {
    const issuedType = curTask.issued_type;
    const orientType = curTask.orient_type;
    const issuedRange = Number(curTask.issued_range);

    const data = params || await queryIssuedResult({
        pid: Number(pid),
        tid
    });
    const activeDevcount = data.active_devcount;
    const orientdevIds = curTask.orient_type === '1' ? activeDevcount : -1; // 指定设备id数

    /*let planDev;
    if (issuedType !== 'issued_special' && orientType !== '1') { // 全量+非指定设备ID
        planDev = curTask.issued_content !== '' || curTask.issued_cond === 'issued_normal' ? '-' : activeDevcount; // 激活设备数
    } else if ((issuedType === 'issued_special' && (orientdevIds >= issuedRange) && orientType === '1')
        || (issuedType === 'issued_special' && orientType !== '1')) { // 定量任务&&指定/非指定ID&&ids大于定量数
        planDev = issuedRange; // 定量目标数
    } else if ((orientType === '1' && (orientdevIds <= issuedRange) && issuedType === 'issued_special')
        || (issuedType !== 'issued_special' && orientType === '1')) { // 指定设备
        planDev = orientdevIds; // 指定设备id数
    }
    return planDev || 0;*/
    return data.planned_upgrade_count;
};

/**
 * @description: 为了解决elm bug而生 降维对象的变量
 * @param {type}
 * @return:
 */
export class listener {
    constructor(name, obj) {
        this.val = obj[name];
        this.obj = obj;
    }

    setData(value) {}

}

/**
 * @description: 为了解决0.5问题而生
 * @param {type}
 * @return:
 */

export const getCorrectNum = (name, num) => {
    if (num === undefined) {
        return 0;
    }

    if (name === 'devCount' || name === 'count') {
        return Math.round(num);
    }

    return Number(Number(num).toFixed(1));
};

export const getOrientType = async (curTask, pid, menuTaskListType) => {
    let orientType = '';
    let orientTypeTxt = '';
    if (curTask.orient_type === '0') {
        orientTypeTxt = '无指定设备';
    }
    else if (curTask.orient_type === '1') {
        orientTypeTxt = '指定设备ID升级';
    }
    else if (curTask.orient_type === '2') {
        orientTypeTxt = '指定设备ID屏蔽';
    }
    else if (curTask.orient_type === '3') {
        orientTypeTxt = '模糊匹配升级';
    }
    else if (curTask.orient_type === '4') {
        orientTypeTxt = '模糊匹配屏蔽';
    }

    // 正式任务
    if (curTask.devidgroupid && Number(curTask.devidgroupid) > 0 && curTask.devid_text_url === '') {
        orientType = `${orientTypeTxt}：设备分组(${curTask.devgroupname
        || (await onGetAllDevidGroupList(Number(pid), curTask.devidgroupid)).name})`;
    }
    // 测试任务
    else if (curTask.testdevidgroupid && Number(curTask.testdevidgroupid) > 0 && menuTaskListType !== 'task'
        && curTask.test_devid_text_url === '') {
        orientType = `${orientTypeTxt}：设备分组(${curTask.testdevgroupname
        || (await onGetAllDevidGroupList(Number(pid), curTask.testdevidgroupid)).name})`;
    }
    else {
        orientType = `${orientTypeTxt}`;
    }
    return orientType;
};

/**
 * 功能：获取设备测试阶段升级结果
 * 状态：DONE
 */
export const queryTaskTestResult = async (taskid = 0, pid = 0) => {
    let params = {
        pid: pid,
        tid: taskid,
        // istest: isTest
    };
    const res = await queryDevIssuedResult(params);
    return res;
};

/**
 * @description: 获取对象类型
 * @param {type}
 * @return:
 */

export const getObjType = function (val) {
    const type = typeof val;
    if (!type === 'object') {
        return type;
    }

    return Object.prototype.toString.call(val).slice(8, -1);
};

/**
 * @description: 二进制数据的base64转化为arraybuffer
 * @param {type}
 * @return:
 */
export const base64ToArrayBuffer = function (base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
};

/**
 * @description: 从文件名取得后缀
 * @param {type}
 * @return:
 */
export const getSuffix = function (fileName) {
    const suffix = fileName.replace(/^.+\./, '');
    return suffix;
};

/**
 * @description: 获取除了后缀的文件名
 * @param {type}
 * @return:
 */
export const getFileNameExcludeSuffix = function (fileName) {
    // 正则匹配咋做
    const lastPointIndex = fileName.lastIndexOf('.');
    if (lastPointIndex === -1) {
        return fileName;
    }

    return fileName.slice(0, Math.abs(lastPointIndex));
};

/**
 * @description: 解析url
 * @param string url
 * @return:
 */
export const praseUrl = function (url) {
    var a = document.createElement('a');
    a.href = url;
    return {
        source: url,
        protocol: a.protocol.replace(':', ''),
        host: a.hostname,
        port: a.port,
        query: a.search,
        params: (function () {
            let params = {};
            let seg = a.search.replace(/^\?/, '').split('&');
            let len = seg.length;
            let p;
            for (let i = 0; i < len; i++) {
                if (seg[i]) {
                    p = seg[i].split('=');
                    params[p[0]] = p[1];
                }

            }
            return params;
        })(),
        hash: a.hash.replace('#', ''),
        path: a.pathname.replace(/^([^\/])/, '/$1')
    };
};

/**
 * @description: 分块获取结果
 * @param {type}
 * @return:
 */

function generateRequset(requests, count) {
    const spliceReq = requests.splice(0, count);
    const p = new Promise(function (resolve, reject) {
        try {
            const res = Promise.all(spliceReq);
            resolve(res);
        }
        catch (error) {
            reject(error);
        }
    });

    p.then(res => {
        if (requests.length > 0) {
            generateRequset(requests, count);
        }

    });
}

function getReq(urls) {
    const allPromises = urls.map(url => {
        return new Promise((resolve) => {
            resolve(fetch(url));
        });
    });
    return allPromises;
}

function init(urls) {
    const promises = getReq(urls);
    generateRequset(promises, 10);
}

/**
 * @description: 生活所迫 转移ts内方法
 * @param {type}
 * @return:
 */

// export const changeAdaptAddTask = function(taskData) {
//     taskData.basic.tname = '';
//     taskData.basic.priority = 10;
//     taskData.target_set.quantity.option = '';
//     taskData.target_set.quantity.quota_quantities = 0;
//     if (taskData.package_data.singlePackage === undefined) {
//         let singlePackage = {
//             ...taskData.package_data.list[0],
//             // show_name: taskData.package_data.list[0].filename,
//             is_encrypted: taskData.package_data.is_encrypted === 1 || taskData.package_data.is_encrypted === 'true' ? true : false
//             // filesize: taskData.package_data.filesize
//         };
//         if (singlePackage.filetype === undefined) {
//             singlePackage.filetype = taskData.package_data.filetype;
//         }
//         if (singlePackage.show_name === undefined) {
//             singlePackage.show_name = singlePackage.filename;
//         }
//         if (taskData.policy.security && taskData.policy.security.token_url) {
//             taskData.policy.security.token_url = taskData.policy.security.token_url.replace(/^http:/, 'https:');
//         }
//         const arr = [singlePackage, taskData.target_set.device_group, taskData.target_set.test_device_group];
//         arr.forEach(item => {
//             if (item.url !== '' && item.url !== undefined) {
//             item.url = item.url.replace(/^http:/, 'https:');
//             }
//         });
//         taskData.package_data.singlePackage = singlePackage;
//         if (taskData.target_set.device_group.show_name === undefined) {
//             taskData.target_set.device_group.show_name = '';
//         }
//         if (taskData.policy.upgrade_timing.is_delay === false) {
//             taskData.policy.upgrade_timing.effective_time = '';
//         }
//         if (taskData.target_set.advanced_expr === undefined) {
//             taskData.target_set.advanced_expr = '';
//         }
//         // 加设备分组show_name;
//         [taskData.target_set.device_group, taskData.target_set.test_device_group].forEach(item => {
//             if (item.url !== '' && item.show_name === '') {
//             item.show_name = getFileNameByURL(item.url);
//             item.device_group_id = 0;
//             }
//         })
//     }
//     if (taskData.target_set.device_group.url && taskData.target_set.device_group.url !== '') {
//         taskData.target_set.device_group.device_group_id = 0;
//     }
//     return taskData;
// }

/**
 * @description: 获取当前任务时间
 * @param {type}
 * @return:
 */

export const getCurIssuedtime = function (canInit, startTime = new Date(), newTime = new Date()) {
    let ret = '00:00:00';
    if (canInit) {
        if (!startTime || startTime === '') {
            startTime = new Date().getTime();
        }

        if (!newTime || newTime === '') {
            newTime = new Date().getTime();
        }

        let now = new Date(startTime).getTime();
        let oldTime = new Date(newTime).getTime();
        let difference = Math.abs(now - oldTime);
        let day = Math.floor((difference / 3600000) / 24);
        let hour = Math.floor((difference / 3600000) % 24);
        let min = Math.floor((difference / 60000) % 60);
        let sec = Math.floor((difference / 1000) % 60);
        if (hour > 0 || day > 0) {
            hour = hour + day * 24;
            hour = hour < 10 ? '0' + hour : hour;
            min = min < 10 ? '0' + min : min;
            sec = sec < 10 ? '0' + sec : sec;
            ret = hour + ':' + min + ':' + sec;
        }
        else if (min > 0) {
            min = min < 10 ? '0' + min : min;
            sec = sec < 10 ? '0' + sec : sec;
            ret = min + ' : ' + sec;
        }
        else if (sec > 0) {
            sec = sec < 10 ? '0' + sec : sec;
            ret = '00 : ' + sec;
        }
    }

    return ret;
};

/**
 * @description: 改变状态的时候添加一下时间呐
 * @param {type}
 * @return:
 */
export const changeStatusAndSetTime = async function (pid, tid, status) {
    try {
        const payload = {
            pid,
            tid,
            status
        };
        const res = await updateTaskStatus(payload); // 更改状态
        const curTask = JSON.parse(sessionStorage.getItem('cur_task'));
        if (res.issueding_time && res.issueding_time !== '') {
            curTask.issueding_time = res.issueding_time;
        }

        if (res.complete_time && res.complete_time !== '') {
            curTask.complete_time = res.complete_time;
        }

        curTask.task_status = status;
        sessionStorage.setItem('cur_task', JSON.stringify(curTask));
    }
    catch (error) {
        console.log(error);
    }
};

/**
 * @description: 二进制形式读取txt文件 并判断其是否为utf-8
 * @param {type}
 * @return:
 */
function readFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (evt) {
            resolve(evt.target.result);
        };
        reader.readAsArrayBuffer(file);
    });
}

export const isUtf8 = async function (file) {
    let res = await readFile(file);
    let firstCode = new Uint8Array(res)[0];
    return firstCode >= 33 && firstCode <= 126;
};
