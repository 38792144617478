import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from '../views/Login/index.vue';

import FirmwareTask from '@/views/CentralMenu/Firmware/index.vue';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/',
        name: 'app',
        component: FirmwareTask,
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
