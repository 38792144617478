<!--
 * @Description:
 * @Author: lindaozheng (lindaozheng@baidu.com)
 * @Date: 2019-05-25 21:17:51
 * @LastEditors: chenruijing
 * @LastEditTime: 2019-11-27 14:46:37
-->
<template>
    <el-row class="upload-com">
        <template v-if="loading.showUpload">
            <ota-uploader
                :file="file"
                :accept="acceptSuffix"
                @change="handleUpload"
            >
                <template>
                    <i class="el-icon-upload" style="margin-right:3px;"></i>
                    本地上传
                </template>
            </ota-uploader>
            <transition name="fade">
                <span class="check-tips">{{ checkTips }}</span>
            </transition>
        </template>
        <div v-else class="pack-upload-prog">
            <template v-if="loading.showProgress">
                <span class="upload-txt">上传进度</span>
                <el-progress :stroke-width="7" :percentage="uploadRate" class="upload-prog"></el-progress>
                <!-- 假取消？ -->
                <!-- 卡得厉害 没时间写 先放着 -->
                <!-- <el-button @click="cancelUploadedFile" type="text">取消</el-button> -->
            </template>
            <template v-else>
                <template v-if="!file || Object.keys(file).length === 0">
                    <i class="el-icon-loading"></i>
                    <span>正在计算文件sha256</span>
                </template>
                <template v-else>
                    <my-popover :remains="13" :content="file.fileName || ''"></my-popover>
                    <el-button
                        @click="deleteUploadedFile"
                        type="text"
                        style="margin-left: 10px"
                    >删除</el-button>
                </template>
                <!-- <my-popover :remains="0" :content="file.pack_md5" :button-style="{color:'#409EFF'}"
                    trigger="click" button-title="查看MD5">
                </my-popover> -->
            </template>
        </div>
    </el-row>
</template>

<script>
import { UPLOADERRORMSG } from '@/assets/js/constants/errMsg';
import { getFileNameExcludeSuffix } from '@/assets/js/common';
import { HashService, getSuffixByFileName } from '@/assets/js/util';

import MyPopover from '@/assets/vue/MyPopover';
import otaUploader from '@/assets/vue/otaUploader';
import { mapState } from 'vuex';
import Uploader from '@/assets/js/bos';

export default {
    name: 'BaseUpload',
    components: {
        'my-popover': MyPopover,
        'ota-uploader': otaUploader
    },
    props: {
        acceptSuffix: {
            type: String,
            // required: true
        },
        bosKey: {
            type: String
        },
        cdnDomain: {
            type: String
        },
        md5Info: {
            type: Object
        },
        file: {
            type: Object,
            default: {}
        },
        isDiff: {
            type: Boolean,
            default: false
        },
        isApk: {
            type: Boolean,
            default: false
        },
        fParams: Object
    },
    data() {
        return {
            uploadRate: 0,
            checkTips: "",
            isCancel: false,
            loading: {
                showUpload: true,
                showProgress: false
            }
        };
    },
    created() {
        if (Object.keys(this.file).length > 0) {
            this.loading = {
                showUpload: false,
                showProgress: false
            }
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        validator(file) {
            const re = /^[0-9a-zA-Z-_()<>（——）|《》【】.]+$/;
            const filenameExcludeSuffixLength = getFileNameExcludeSuffix(file.name).length;
            const suffix = getSuffixByFileName(file.name);
            const appFileSuffix = ['apk', 'APK', 'ipa', 'IPA'];
            if (file.size === 0) {
                this.displayErrMsg('empty');
                return false;
            }
            if (!re.test(file.name)) {
                this.displayErrMsg('illegal');
                return false;
            }

            if (filenameExcludeSuffixLength > 60) {
                this.displayErrMsg('tooLong');
                return false;
            }
            if (this.isApk && !appFileSuffix.includes(suffix)) {
                this.displayErrMsg('formatError');
                return false;
            }
            if (!this.isApk && appFileSuffix.includes(suffix)) {
                this.displayErrMsg('formatError');
                return false;
            }
            this.displayErrMsg();
            return true;
        },
        displayErrMsg(flag) {
            // 版本提示
            const tipItem = UPLOADERRORMSG.find(item => item.value === flag);
            const hasError = tipItem !== undefined;
            this.checkTips = hasError ? tipItem.msg : '';
            this.loading.showUpload = hasError ? true : false;
        },
        async handleUpload(file) {
            if (!this.validator(file)) {
                return;
            }
            // 去不掉 牛皮藓
            const fileName = file.name.replace(/\s/g, '');

            this.isCancel = false;
            try {
                const key = `security-scan-web/scanfile/${new Date().getTime()}/${fileName}`;
                const uploader = new Uploader();
                const hashService = new HashService();
                this.loading.showProgress = true;
                await uploader.uploadLargeFile({
                    blob: file,
                    key,
                    fileName,
                    onProgress: rate => {
                        this.uploadRate = +(rate * 100).toFixed(1);
                    },
                    onPartFileResolve(partFile, partNumber) {
                        hashService.update(partFile, partNumber);
                    }
                });
                this.loading.showProgress = false;
                const results = await hashService.getResults();
                const data = {
                    fileName: file.name,
                    fileSize: file.size,
                    fileSha1: results.sha1,
                    fileMd5: results.md5, // 文件标识
                    fileUrl: 'https://sec-scan-faw-vk.cdn.bcebos.com/' + key,
                    fileSha256: results.sha256,
                    blob: file
                };
                this.fileName = fileName;
                if (!this.isCancel) {
                    this.$emit('update:file', data);
                    this.$emit('initShowName', getFileNameExcludeSuffix(data.fileName));
                }
            } catch (err) {
                console.log(err);
                this.cancelUploadedFile();
                this.$message.error('上传发生错误')
            };
        },
        cancelUploadedFile() {
            // bos暂未实现中断上传
            this.deleteUploadedFile();
            this.isCancel = true;
        },
        deleteUploadedFile() {
            this.loading = {
                showUpload: true,
                sha1Loading: true,
                showProgress: false
            };
            this.uploadRate = 0;
            this.checkTips = '';
            this.$emit('update:file', {});
        }
    }
};
</script>

<style lang="scss" scoped>
.upload-com {
    .pack-upload-prog {
        display: flex;
        align-items: center;
        // word-break: break-all;
        .upload-txt {
            display: inline-block;
            color: #606266;
            width: auto;
            padding-right: 12px;
            text-align: right;
            font-size: 12px;
        }
        .sha1-txt {
            font-size: 12px;
            color: #409eff;
        }
        .upload-prog {
            white-space: normal;
            width: 100px;
        }
    }
    .upload-label {
        display: inline-block;
        width: 60px;
        padding-right: 12px;
        text-align: right;
        font-size: 12px;
        color: #606266;
    }
    .check-tips {
        position: absolute;
        top: 0;
        left: 100px;
        font-size: 12px;
        color: #f56c6c;
        white-space: nowrap;
    }
    // .fade-enter-active,
    // .fade-leave-active {
    //     transition: opacity 0.5s;
    // }
    // .fade-enter,
    // .fade-leave-to {
    //     opacity: 0;
    // }
}
</style>
