/**
 * @file: common js codes
 * @Author: lindaozheng (lindaozheng@baidu.com)
 * @Date: 2019-05-25 21:17:51
 * @LastEditors: sunchao
 * @LastEditTime: 2020-04-20 11:21:56
 */

import CryptoJS from 'crypto-js/core';
import WordArray from 'crypto-js/lib-typedarrays';
import 'crypto-js/sha1';
import 'crypto-js/md5';
import 'crypto-js/sha256';
import 'rxjs/add/operator/concatMap';
const Observable = require('rxjs');

export default {
    install(Vue, options) {
        Vue.prototype.isObjEmpty = function (obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        };
    }
};

export function isObjEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function getFileNameByURL(url) {
    return url.split('/').pop();
}

export function getSuffixByFileName(filename) {
    return filename.split('.').pop();
}

export function getFileTypeByFileName(filename) {
    const suffixName = filename.split('.').pop();
    if (suffixName === 'zip') {
        return 0;
    }
    else if (suffixName === 'apk') {
        return 1;
    }
    else if (suffixName === 'bdota') {
        return 2;
    }
    else {
        return -1;
    }
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }

    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        let str = o[k] + '';
        if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }

    }
    return fmt;
}

export function formatUnixtime(date) {
    var yy = date.getFullYear(); // 年
    var mm = date.getMonth() + 1; // 月
    var dd = date.getDate(); // 日
    var hh = date.getHours(); // 时
    var ii = date.getMinutes(); // 分
    var ss = date.getSeconds(); // 秒
    var clock = yy + '-';
    if (mm < 10) {
        clock += '0';
    }

    clock += mm + '-';
    if (dd < 10) {
        clock += '0';
    }

    clock += dd + ' ';
    if (hh < 10) {
        clock += '0';
    }

    clock += hh + ':';
    if (ii < 10) {
        clock += '0';
    }

    clock += ii + ':';
    if (ss < 10) {
        clock += '0';
    }

    clock += ss;
    return clock;
}

export function getNowFormatDate() {
    let date = new Date();
    let seperator1 = '-';
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = '0' + month;
    }

    if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
    }

    let currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}

export function getYesterdayFormatDate() {
    let date = new Date();
    date.setTime(date.getTime() - 24 * 60 * 60 * 1000);
    let seperator1 = '-';
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
        month = '0' + month;
    }

    if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate;
    }

    let currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
}

// sDate1和sDate2是2006-12-18格式
export function datedifference(sDate1, sDate2) {
    let dateSpan;
    let iDays;
    sDate1 = Date.parse(sDate1);
    sDate2 = Date.parse(sDate2);
    dateSpan = sDate2 - sDate1;
    dateSpan = Math.abs(dateSpan);
    iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
    return iDays;
}

export function getWeek(i, start = null) {
    let nowdate = null;
    if (start == null || start === '') {
        nowdate = new Date();
        i = i + 1;
    }
    else {
        nowdate = new Date(start).getTime();
    }
    let oneweekdate = new Date(nowdate - i * 24 * 3600 * 1000);
    let curMonth = (oneweekdate.getMonth() + 1).toString();
    let curDate = oneweekdate.getDate().toString();
    let y = oneweekdate.getFullYear();
    let m = curMonth.length === 1 ? `0${curMonth}` : curMonth;
    let d = curDate.length === 1 ? `0${curDate}` : curDate;
    let formatwdate = y + '-' + m + '-' + d;
    return formatwdate;
}

export function conver(limit) {
    let size = '';
    if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
        size = limit.toFixed(2) + 'B';
    }
    else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + 'KB';
    }
    else if (limit < 1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB';
    }
    else if (limit < 1 * 1024 * 1024 * 1024 * 1024) { // 如果小于0.1TB转化成GB {
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
    }
    else { // 其他转化成TB
        size = (limit / (1024 * 1024 * 1024 * 1024)).toFixed(2) + 'TB';
    }

    let sizestr = size + '';
    let len = sizestr.indexOf('\.');
    let dec = sizestr.substr(len + 1, 2);
    if (dec === '00') { // 当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }

    return sizestr;
}

// 是否是正式环境
export const IsOnline = param => {
    if (process.env.VUE_APP_TITLE === 'production') {
        let localhost = location.host;
        // 8088是测试环境
        if (localhost.indexOf('8080') !== -1) {
            return false;
        }

        return true;
    }

    return false;
};

/**
 * 生成从 start 到 end 且步长为 step 的序列
 */
function* range(start, end, step) {
    for (let i = start; i < end; i += step) {
        yield i;
    }
}

/**
 * 读取一个 Blob
 *
 * @return Promise[ArrayBuffer]
 */
export function readAsArrayBuffer(blob) {
    const reader = new FileReader();
    const p = new Promise((resolve, reject) => {
        reader.onload = function (e) {
            resolve(e.target.result);
        };
        reader.onerror = function (e) {
            reject(e.target.error);
        };
    });
    reader.readAsArrayBuffer(blob);
    return p;
}

/**
* 分片读取 Blob
*
* @return Observable[ArrayBuffer]
*/
export function readAsObservable(blob, chunkSize) {
    return Observable.from(range(0, blob.size, chunkSize)).concatMap(start => {
        const endOffset = Math.min(start + chunkSize, blob.size);
        return Observable.from(readAsArrayBuffer(blob.slice(start, endOffset)));
    });
}

export const CHUNK_SIZE = 16 * 1024 * 1024; // 计算 hash 时采用 16MB 的分片
export function hashFile(file, chunksize = CHUNK_SIZE) {
    return new Promise(function (resolve, reject) {
        const hashSha1 = CryptoJS.algo.SHA1.create();
        const hashMd5 = CryptoJS.algo.MD5.create();
        readAsObservable(file, chunksize).subscribe(
            buf => {
                hashSha1.update(WordArray.create(buf));
                hashMd5.update(WordArray.create(buf));
            },
            err => {
                console.error(err);
                reject(err);
            },
            () => {
                const hashRes = {};
                hashRes.sha1 = hashSha1.finalize().toString();
                hashRes.md5 = hashMd5.finalize().toString();
                resolve(hashRes);
            }
        );
    });
}

const sha1 = require('js-sha1');
export function sha1File(fileobj) {
    return new Promise(function (resolve, reject) {
        let run = function (file) {
            let reader = new FileReader();

            reader.onload = function (e) {
                let result = e.result || e.target.result;
                file.sha1Hash = sha1(result);
                resolve(file);
            };
            let blob = file.slice(0, file.size);
            reader.readAsArrayBuffer(file);
        };

        let checkApi = function () {
            if (typeof File === 'undefined') {
                return false;
            }

            if (!File.prototype.slice) {
                if (File.prototype.webkitSlice) {
                    File.prototype.slice = File.prototype.webkitSlice;
                }
                else if (File.prototype.mozSlice) {
                    File.prototype.slice = File.prototype.mozSlice;
                }
            }

            if (!window.File || !window.FileReader || !window.FileList || !window.Blob || !File.prototype.slice) {
                return false;
            }

            return true;
        };

        if (checkApi()) {
            run(fileobj);
        }
        else {
            return false;
        }
    });
}
// 校验文件非法字符
export function checkFileInValidChar(file) {
    return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        reader.onload = function (e) {
            let result = e.result || e.target.result;
            let reg1 = /^[0-9a-zA-Z]+$/;
            let regEn = /[`~!@#$%^&()+<>"{},.;'[\]=]/im;
            let regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]=\\]/im;
            let cnZh = /[\u4e00-\u9fa5]/;
            let regWhite = / /;
            let value = result;
            let res = 0;
            if (cnZh.test(value) || regEn.test(value) || regCn.test(value) || regWhite.test(value)) {
                res = 1;
            }
            else {
                let len = value.replace(/\s+/g, ',').split(',').length;
                if (len > 100) {
                    res = 2;
                    if (len > 1000000) {
                        res = 4;
                    }
                }
                else if (value == null || !value.replace(/\s+/g, '').length) {
                    res = 3;
                }
            }
            resolve(res);
        };
        reader.readAsText(file, 'gb2312');
    });
}

export function GetRequest() {
    let url = location.search; // 获取url中"?"符后的字串
    let theRequest = {};
    if (url.indexOf('?') !== -1) {
        let str = url.substr(1);
        let strs = str.split('&');
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = unescape(strs[i].split('=')[1]);
        }
    }

    return theRequest;
}

// 将字符串转换成二进制形式，中间用空格隔开
export function strToBinary(str) {
    let result = [];
    let list = str.split('');
    for (let i = 0; i < list.length; i++) {
        if (i !== 0) {
            result.push(' ');
        }

        let item = list[i];
        let binaryStr = item.charCodeAt().toString(2);
        result.push(binaryStr);
    }
    return result.join('');
}

// 将二进制字符串转换成Unicode字符串
export function binaryToStr(str) {
    let result = [];
    let list = str.split(' ');
    for (let i = 0; i < list.length; i++) {
        let item = list[i];
        let asciiCode = parseInt(item, 2);
        let charValue = String.fromCharCode(asciiCode);
        result.push(charValue);
    }
    return result.join('');
}

export class HashService {
    constructor() {
        this.hashObjects = {
            sha1: CryptoJS.algo.SHA1.create(),
            md5: CryptoJS.algo.MD5.create(),
            sha256: CryptoJS.algo.SHA256.create()
        };
        this.promiseArray = [];
    }

    update(blob, partNumber) {
        let promise = null;
        let updateFunc = function (hashObjects) {
            return readAsArrayBuffer(blob).then(data => {
                for (let key in hashObjects) {
                    hashObjects[key].update(WordArray.create(data));
                }
                return hashObjects;
            });
        };

        if (this.promiseArray.length === 0) {
            promise = Promise.resolve(this.hashObjects);
        }
        else {
            promise = this.promiseArray[this.promiseArray.length - 1];
        }
        this.promiseArray.push(promise.then(updateFunc));
    }

    getResults() {
        return Promise.all(this.promiseArray).then(results => {
            let hashResults = {};
            for (let key in this.hashObjects) {
                hashResults[key] = this.hashObjects[key].finalize().toString();
            }
            return hashResults;
        });
    }
}

// export const FILE_NAME_VALIDATE_REGEX = /^[0-9a-zA-Z()（）\-_——【】.《》|<>]+$/;
export const FILE_NAME_VALIDATE_REGEX = /^[0-9a-zA-Z-_()<>（——）|《》【】.\u4e00-\u9fa5]*$/;

export function validateFileName(filename) {
    if (!filename || filename.length === 0) {
        return false;
    }

    return FILE_NAME_VALIDATE_REGEX.test(filename);
    // return /^[0-9a-zA-Z-_()<>（——）|《》【】.\u4e00-\u9fa5]*$/.test(filename);
}

export function validateDeviceID(deviceId) {
    return /^[\d\w\/\*\-\:\?]+$/.test(deviceId);
}

export function validateDevTextFile(file, allowMaxLine, allowMaxSize = 30) {
    return new Promise((resolve, reject) => {
        if (Number(conver(file.size).replace(/MB/, '')) > allowMaxSize) {
            reject(`上传文件大小不能超过${allowMaxSize}MB!`);
            return;
        }

        let reader = new FileReader();
        reader.onload = function (event) {
            let result = event.result || event.target.result;
            let deviceIds = result.replace(/(\r\n|\r)+/g, '\n').split('\n');
            if (deviceIds.length === 0
                || (deviceIds.length === 1 && deviceIds[0] === '')) {
                reject('上传设备ID的txt文件为空');
                return;
            }

            if (deviceIds.length > allowMaxLine) {
                reject(`最多可导入${allowMaxLine}个设备，您导入设备量为${deviceIds.length}`);
                // reject(`最多可导入${allowMaxLine}个设备`);
                return;
            }

            // check设备ID有效性，需要支持几十w条数据，用最高效的遍历方式
            let length = deviceIds.length;
            let index = 0;
            let blankLines = 0;
            for (; index < length; index++) {
                let deviceId = deviceIds[index];
                if (deviceId === '') {
                    continue; // skip blank line
                }

                if (!validateDeviceID(deviceId)) {
                    reject(`设备ID包含非法字符，设备ID：${deviceId}`);
                    return;
                }

            }
            resolve();
        };
        reader.readAsText(file, 'utf-8');
    });
}

export function base64toBlob(base64) {
    let arr = base64.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
