/**
 * @file: Describe the file
 * @author: sunchao15@baidu.com
 * @Date: 2019-02-13 16:39:46
 * @Last Modified by: luocixing
 * @Last Modified time: 2020-12-10 10:48:20
 */

let otaServer = '/api';
let otaServer_v1 = '/v1';
let OtaServerV1 = '/otapi/v1';
let otaRoot = '/';
let otaServerV2 = '/api/v2';
let mock;
let deviceServe = '/aiotdevice';
let securityV1 = '/api/v1';

if (process.env.VUE_APP_TITLE === 'production' || process.env.VUE_APP_TITLE === 'testEnv') {
    // 部署服务调用正式地址
    // otaRoot = '/platform/';
    otaServer = window.location.protocol + '//' + location.host + '/api';
    otaServer_v1 = window.location.protocol + '//' + location.host + '/v1';
    OtaServerV1 = window.location.protocol + '//' + location.host + '/api/v1';
    otaServerV2 = window.location.protocol + '//' + location.host + '/api/v2';
    deviceServe = window.location.protocol + '//' + location.host + '/aiotdevice';
    securityV1 = window.location.protocol + '//' + location.host + '/api/v1';
}
else {
    // 开发测试地址
    // otaRoot = '/platform/';
    otaServer = '/otapi';
    mock = 'http://localhost:9092';
    deviceServe = '/aiotdevice';
}

export {otaRoot, otaServer, otaServer_v1, OtaServerV1, otaServerV2, mock, deviceServe, securityV1};
