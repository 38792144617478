/**
 * @file:
 * @author: sunchao(sunchao15@baidu.com)
 * @Date: 2019-09-17 16:13:07
 * @LastEditors: sunchao
 * @LastEditTime: 2020-04-21 17:46:40
 */

export const VERSIONERRORMSG = [{
    msg: '请选择版本限定',
    value: -3
}, {
    msg: '终止版本号格式错误',
    value: -2
}, {
    msg: '请填写起始版本号',
    value: -1
}, {
    msg: '版本号格式错误',
    value: 0
}, {
    msg: '版本号区间不正确',
    value: 1
}, {
    msg: '该版本号已存在',
    value: 2
}];

export const REGIONERRORMSG = [{
    msg: '该地域已经存在',
    value: 'exist'
}, {
    msg: '最多只能添加10个地域',
    value: 'exceed'
}, {
    msg: '请选择地域限定',
    value: 'empty'
}];

export const CHANNELERRORMSG = [{
    msg: '请勿重复添加渠道号',
    value: 'exist'
}, {
    msg: '渠道数量已达到上限',
    value: 'exceed'
}, {
    msg: '请添加渠道号',
    value: 'empty'
}, {
    msg: '格式错误,请输入20位以下的渠道号',
    value: 'format'
}, {
    msg: '该项为必填项',
    value: 'required'
}];

export const CACHEERRORMSG = [{
    msg: '请填写cache大小',
    value: 'empty'
}, {
    msg: '请输入1-1000000以内的正整数',
    value: 'exceed'
}, {
    msg: '该项为必填项',
    value: 'required'
}];

export const UPLOADERRORMSG = [{
    msg: '传入文件为空，请检查后重试',
    value: 'empty'
}, {
    msg: '传入文件包含非法字符，请检查后重试',
    value: 'illegal'
}, {
    msg: '文件名应小于64个字符, 请检查后重试',
    value: 'tooLong'
}, {
    msg: '已存在相同包文件，请检查后重试',
    value: 'exist'
}, {
    msg: '上传文件发生错误',
    value: 'uploadError'
}, {
    msg: '上传文件格式不正确',
    value: 'formatError'
}];
