<template>
    <div class="firmware-container">
        <el-row
            type="flex"
            align="middle"
            justify="space-between"
        >
            <div class="firmware-title">
                {{ isApp ? '应用检测' : '固件检测' }}
            </div>
            <div class="firmware-header">
                已检测APP个数：{{ usedCount }}个 / 可用APP个数：{{ availableCount }}个
            </div>
        </el-row>
        <el-row
            type="flex"
            align="middle"
            justify="space-between"
            style="margin-top: 20px"
        >
            <el-button
                type="primary"
                @click="createTask"
            >新建检测任务</el-button>
            <el-input
                :placeholder="isApp ? '应用名称/包名/版本号查找' : '固件名称搜索'"
                style="width: 300px"
                v-model="keyword"
                @input="search"
            >
                <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </el-row>
        <div style="margin-top: 20px">
            <el-table
                v-loading="loading"
                :data="tableData"
                border
            >
                <el-table-column
                    v-if="!isApp"
                    prop="task_name"
                    label="固件名称"
                    align="center"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                    v-if="isApp"
                    label="应用信息"
                    align="center"
                    prop="task_info"
                    width="250"
                >
                    <template slot-scope="scope">
                        <el-row
                            v-if="scope.row.file_info && scope.row.file_info.appPackageName"
                            type="flex"
                        >
                            <img
                                :src="scope.row.file_info.appIcon"
                                width="40"
                                height="40"
                                style="margin-right: 10px"
                            >
                            <div style="text-align: left;">
                                {{ scope.row.file_info.appName }}
                                <div style="font-size: 12px">包名：{{ scope.row.file_info.appPackageName }}</div>
                                <div style="font-size: 12px">版本号：{{ scope.row.file_info.appVersion }}</div>
                                <div style="font-size: 12px">大小：{{ conver(scope.row.file_info.fileSize) }}</div>
                            </div>
                        </el-row>
                        <div v-else>{{ scope.row.task_name }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="isApp"
                    prop="task_type"
                    label="应用类型"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span>{{ getAppType(scope.row.task_type) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="file_md5"
                    label="MD5"
                    align="center"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                    prop="task_start_time"
                    label="任务开始时间"
                    align="center"
                    show-overflow-tooltip
                ></el-table-column>
                <el-table-column
                    prop="status"
                    label="任务状态"
                    align="center"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <el-tag :type="statusColor(scope.row)">{{ checkStatus(scope.row) }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="detection_rules_type"
                    label="检测规则"
                    align="center"
                    show-overflow-tooltip
                >
                    <template slot-scope="scope">
                        <span>{{ checkRules(scope.row) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            class="operation-btn"
                            type="text"
                            @click="downloadReport(scope.row.report_url)"
                            :disabled="!scope.row.report_url"
                        >下载报告</el-button>
                        <el-button
                            class="operation-btn"
                            type="text"
                            :disabled="scope.row.task_status !== 'successed'"
                            @click="showOtherReportDialog(scope.row.report_html_url)"
                        >查看报告</el-button>
                        <el-button
                            class="operation-btn"
                            type="text"
                            :disabled="scope.row.task_status !== 'successed' && scope.row.task_status !== 'failed'"
                            @click="deleteTask(scope.row)"
                        >删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-row
            v-if="tableData.length > 0 && pagination.total > pagination.page_size"
            type="flex"
            justify="end"
            style="margin-top: 20px"
        >
            <el-pagination
                background
                layout="prev, pager, next"
                :total="pagination.total"
                @current-change="changeCurrent">
            </el-pagination>
        </el-row>
        <create-dialog
            v-if="showCreateDialog"
            :showCreateDialog.sync="showCreateDialog"
            @updateData="updateData"
        ></create-dialog>
        <!-- <el-dialog
            class="firmware-tips-dialog"
            title="提示"
            :visible.sync="showTipsDialog"
            width="400px"
            :close-on-click-modal="false"
        >
            <span>当前产品线无{{this.isApp ? '应用扫描' : '固件扫描'}}可用额度，请联系百度团队了解如何增加额度。</span>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="showTipsDialog = false">确 定</el-button>
            </span>
        </el-dialog> -->
        <el-dialog
            class="reportHtml-dialog"
            title="报告列表"
            :visible.sync="showReportDialog"
            :close-on-click-modal="false"
        >
            <el-row
                v-for="item in reportHtmlList"
                :key="item.url"
                type="flex"
                align="middle"
            >
                <el-col :span="17" :offset="3">{{ item.name }}</el-col>
                <el-col :span="4">
                    <el-button
                        type="text"
                        @click="lookOtherReport(item.url)"
                        :disabled="!item.url"
                    >查看</el-button>
                </el-col>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import _ from "lodash";
import {
    getSecurityList,
    delSecurityTask,
    getSecuritySummary
} from '@/api/';
import download from '@/assets/vue/download';
import createDialog from './components/CreateDialog.vue';
import { conver } from '../../../assets/js/util';
export default {
    name: 'firmware',
    components: {
        createDialog
    },
    mixins: [download],
    data() {
        return {
            loading: false,
            // dialog
            showTipsDialog: false,
            showCreateDialog: false,
            showReportDialog: false,
            pid: '',
            availableCount: 0,
            usedCount: 0,
            pagination: {
                current: 1,
                page_size: 10,
                total: 0
            },
            tableData: [],
            reportHtmlList: [],
            keyword: ''
        }
    },
    computed: {
        isApp() {
            return this.$route.name === 'app'
        }
    },
    created() {
        // this.pid = Number(this.$route.params.pid);
        this.getSecuritySummary();
        this.getSecurityList();
    },
    methods: {
        // 查看其它报告
        lookOtherReport(url) {
            window.open(url, 'target');
        },
        getAppType(type) {
            if (!type) {
                return '无';
            }
            const typeObj = {
                app: 'Android APK',
                ipa: 'iOS APK'
            };
            return typeObj[type];
        },
        showOtherReportDialog(data) {
            if (data.length && data.length === 1) {
                this.lookOtherReport(data[0].url);
                return;
            }
            this.reportHtmlList = data;
            this.showReportDialog = true;
        },
        updateData() {
            this.getSecuritySummary();
            this.getSecurityList();
        },
        async getSecuritySummary () {
            try {
                const payload = {
                    type: 'app'
                };
                const data = await getSecuritySummary(payload);
                this.usedCount = data.totalCount - data.availableCount;
                this.availableCount = data.availableCount
            } catch (err) {
                console.log(err);
                this.availableCount = 0;
            }
        },
        async getSecurityList(keyword = '') {
            this.loading = true;
            try {
                const { current, page_size } = this.pagination;
                const payload = {
                    query: {
                        page: current,
                        size: page_size,
                        keyword: keyword
                    }
                };
                const data = await getSecurityList(payload);
                this.tableData = data.data;
                this.pagination.total = data.total;
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false;
            }
        },
        // 创建任务
        createTask() {
            // let { availableCount } = this;
            // if (availableCount === 0) {
            //     this.showTipsDialog = true;
            //     return;
            // }
            this.showCreateDialog = true;
        },
        // 删除任务
        async deleteTask(row) {
            await this.$confirm(`是否删除任务【${row.task_name}】`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            try {
                const taskId = row.task_id;
                const payload = {
                    params: [taskId]
                };
                await delSecurityTask(payload);
                this.$message({
                    type: 'success',
                    message: '删除成功'
                });
                this.getSecurityList();
            }
            catch (err) {
                this.$message.error('删除失败，请重试!');
            }
        },
        // 下载报告
        downloadReport: _.throttle(function (url) {
            this.fetchDataByURL(url);
        }, 1000),
        // 检测规则
        checkRules(row) {
            const rules = row.task_engine_custom;
            const rulesObj = {
                apk: 'apk',
                ipa: 'ipa',
                basic: '基础检测',
                dengbao2_II: '等保2.0二级',
                dengbao2_III: '等保2.0三级'
            };
            return rulesObj[rules];
        },
        // 任务状态
        checkStatus(row) {
            const statusObj = {
                'running': '扫描中',
                'report_creating': '报告创建中',
                'successed': '扫描成功',
                'failed': '扫描失败 ',
                'deleted': '已删除',
            };
            return statusObj[row.task_status];
        },
        // 任务状态样式
        statusColor(row) {
            const statusObj = {
                'running': 'primary',
                'report_creating': 'primary',
                'successed': 'success',
                'failed': 'danger',
                'deleted': 'info',
            };
            return statusObj[row.task_status];
        },
        changeCurrent(page) {
            this.pagination.current = page;
            this.getSecurityList();
        },
        search: _.debounce(function (value) {
            this.getSecurityList(value);
        }, 500),
        conver,
        get: _.get
    }
}
</script>
<style lang="scss" scoped>
.firmware-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;
    .firmware-title {
        font-size: 16px;
        color: #333;
    }
    .firmware-header {
        font-size: 14px;
        color: #333;
    }
}
</style>