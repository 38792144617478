<template>
    <div class="o-login">
        <div class="login-wrapper">
            <img
                src="@/assets/img/pic.png"
                class="pic"
            />
            <div class="custom-login-container">
                <div class="title"></div>
                <div style="font-size: 16px; margin-top: 5px; margin-bottom: 40px; color: #777666;">
                    应用检测平台
                </div>
                <el-form
                    :rules="rules"
                    :model="params"
                    ref="form"
                >
                    <el-form-item prop="uname">
                        <el-input
                            class="login-input"
                            placeholder="手机/邮箱/用户名"
                            v-model="params.uname"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            class="login-input"
                            type="password"
                            placeholder="密码"
                            v-model="params.password"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="captcha">
                        <el-row
                            type="flex"
                            align="middle"
                            justify="space-between"
                        >
                            <div style="width: 150px; height: 40px;margin-right: 10px;">
                                <img
                                    style="width: 100%; height: 100%"
                                    :src="`/api/v2/auth/get_captcha?${timestamp}`"
                                    @click="refreshCaptcha"
                                >
                            </div>
                            <el-input
                                style="width: 125px"
                                v-model="params.captcha"
                                placeholder="验证码"
                            ></el-input>
                        </el-row>
                    </el-form-item>
                </el-form>
                <el-button
                    class="login-button"
                    type="primary"
                    @click="onLoginClick"
                    :loading="loading"
                >
                    登录
                </el-button>
                <div class="footer"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { login } from '../../api';
export default {
    name: 'o-login',
    data() {
        return {
            params: {
                uname: '',
                password: '',
                captcha: ''
            },
            rules: {
                uname: [
                    {
                        required: true,
                        message: '请输入账号'
                    },
                    {
                        max: 128,
                        message: '账号超出最大长度'
                    }
                ],
                password: [
                    {
                        required: true,
                        message: '请输入密码'
                    },
                    {
                        max: 128,
                        message: '密码超出最大长度'
                    }
                ],
                captcha: [
                    {
                        required: true,
                        message: '请输入验证码'
                    },
                    {
                        max: 128,
                        message: '验证码超出最大长度'
                    }
                ]
            },
            timestamp: Date.now(),
            loading: false
        };
    },
    methods: {
        ...mapMutations(['setUserInfo']),
        async onLoginClick() {
            await this.$refs.form.validate();
            this.loading = true;
            const { uname, password, captcha } = this.params;
            try {
                await login({ uname, password, captcha })
                // const data = await this.$api.getUserInfo();

                this.$router.push({
                    name: 'app'
                });
            } catch (_) {
                this.refreshCaptcha();
            } finally {
                this.loading = false;
            }
        },
        refreshCaptcha() {
            this.timestamp = Date.now();
        }
    },
    created() {
        this.onKeyPress = (function (e) {
            if (e.key === 'Enter' && !this.$loading.login && !this.$loading.getToken) {
                this.onLoginClick();
            }
        }).bind(this);
        window.addEventListener('keypress', this.onKeyPress);
    },
    destroyed() {
        window.removeEventListener('keypress', this.onKeyPress);
    }
};
</script>
<style scoped>
.o-login {
    width: 100%;
}

header {
    height: 60px;
    border-bottom: 2px solid #f1f1f1;
}

.logo {
    height: 32px;
    margin-top: 14px;
    margin-left: 20px;
}

.login-wrapper {
    display: flex;
    height: 414px;
    margin-top: 80px;
    justify-content: center;
}

.pic {
    height: 100%;
}

.login-container {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    float: left;
    margin-left: 50px;
    margin-left: 1.7%;
    width: 350px;
    background: #fff;
    border: 1px solid #eaeaea;
}

.title {
    margin: 0px auto;
    text-align: center;
    color: #505458;
}

.remember {
    margin: 0px 0px 35px 0px;
}

.custom-login-container {
    border-radius: 5px;
    float: left;
    margin-left: 50px;
    margin-left: 1.7%;
    width: 350px;
    background: #fff;
    border: 1px solid #eaeaea;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    width: 250px;
    font-size: 16px;
    height: 30px;
    line-height: 46px;
    color: #000;
    background-image: url('~@/assets/img/logo.svg');
    background-size: auto 30px;
    background-repeat: no-repeat;
    background-position-x: center;
}

.login-input {
    width: 284px;
}

.login-button {
    margin-top: 45px;
    width: 284px;
}

.login-input::v-deep input {
    height: 42px;
}
</style>
