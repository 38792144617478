<template>
    <el-dialog
        class="firmware-create-dialog"
        :title="createTitle"
        width="450px"
        :visible="true"
        :close-on-click-modal="false"
        @close="$emit('update:showCreateDialog', false)"
    >
        <el-form
            ref="createForm"
            class="firmware-create-form"
            label-width="80px"
            :model="form"
            :rules="rules"
            :hide-required-asterisk="true"
        >
            <el-form-item
                v-if="!isApp"
                :label="createLabel"
                prop="name"
            >
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item ref="file" :label="uploadLabel" prop="package">
                <base-uploader
                    ref="upload"
                    :file.sync="form.file"
                    :accept-suffix="accept"
                    :is-apk="isApp"
                    @init-show-name="initShowName"
                >
                    <template slot="upload-button">
                        <el-button
                            size="small"
                            type="primary"
                        >
                            <i class="el-icon-upload" style="margin-right:3px;"></i>
                            本地上传
                        </el-button>
                    </template>
                </base-uploader>
                <span v-if="isApp" style="color: #909399; position: relative; top: -10px">
                    支持5G的apk、ipa的文件检测
                </span>
            </el-form-item>
            <el-form-item
                v-if="isApp && apkType"
                label="应用类型"
            >
                <span>{{ apkType }}</span>
            </el-form-item>
            <template v-if="isApp && ['apk', 'ipa'].includes(fileType) && fileInfo">
                <h3 style="margin-bottom: 22px; font-size: 16px;">应用信息</h3>
                <el-form-item label="应用图标">
                    <img
                        width="30"
                        height="30"
                        :src="fileInfo.appIcon"
                    >
                </el-form-item>
                <el-form-item label="应用名称">
                    <el-input
                        v-model="fileInfo.appName"
                        placeholder="若未解析出应用名称，请自行填写"
                    ></el-input>
                </el-form-item>
                <el-form-item label="应用包名">
                     <span>{{ fileInfo.appPackageName }}</span>
                </el-form-item>
                <el-form-item label="版本号">
                     <span>{{ fileInfo.appVersion }}</span>
                </el-form-item>
                <el-form-item label="包大小">
                     <span>{{ conver(fileInfo.fileSize) }}</span>
                </el-form-item>
            </template>
            <el-form-item label="检测规则" v-if="!isApp">
                <el-select v-model="form.rules" placeholder="请选择检测规则">
                    <template v-if="!isApp">
                        <el-option
                            v-for="item in firmwareOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </template>
                    <template v-else>
                        <el-option
                            v-for="item in appOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </template>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                type="primary"
                :loading="isConfirm || parsing"
                :disabled="!form.file.fileUrl"
                @click="confirmScan('createForm')"
            >开始扫描</el-button>
            <el-button @click="cancelScan">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { createSecurityTask } from '@/api/';
import AppInfoParser from 'app-info-parser/dist/app-info-parser.min.js';
import baseUploader from './BaseUploader';
import { getSuffixByFileName, conver, base64toBlob } from '@/assets/js/util';
import Uploader from '@/assets/js/bos';

export default {
    name: 'create-task',
    components: {
        'base-uploader': baseUploader
    },
    props: {
        showCreateDialog: { type: Boolean }
    },
    data() {
        const checkName = (rule, value, callback) => {
            const reg = /^[A-Za-z0-9._-]+$/;
            if (!value) {
                return callback(new Error('固件名称不能为空'));
            }
            if (!reg.test(value)) {
                return callback(new Error('固件名称存在特殊字符'));
            }
            callback();
        };
        const checkFile = (rule, value, callback) => {
            const { file } = this.form;
            if (Object.keys(file).length === 0) {
                return callback(new Error('请上传文件'));
            }
            callback();
        };
        return {
            isConfirm: false,
            parsing: false,
            form: {
                name: '',
                rules: 'basic',
                file: {}
            },
            appOption: [
                { label: '基础检测', value: 'apk' }
            ],
            firmwareOption: [
                { label: '基础检测', value: 'basic' },
                { label: '等保2.0二级', value: 'dengbao2_II' },
                { label: '等保2.0三级', value: 'dengbao2_III' }
            ],
            rules: {
                name: [{
                    required: true, 
                    validator: checkName, 
                    trigger: ['blur', 'change']
                }],
                package: [{
                    required: true,
                    validator: checkFile,
                    trigger: 'change'
                }]
            },
            fileType: '',
            fileInfo: null
        }
    },
    computed: {
        isApp() {
            return this.$route.name === 'app';
        },
        apkType() {
            const fileName = this.form.file.fileName;
            if (!fileName) {
                return;
            }
            const fileTypeObj = {
                apk: 'Android APK',
                ipa: 'iOS APK'
            };
            const fileType = getSuffixByFileName(fileName);
            this.fileType = fileType;
            return fileTypeObj[fileType];
        },
        createTitle() {
            return this.isApp ? '新建应用检测任务' : '新建固件检测任务';
        },
        createLabel() {
            return this.isApp ? '应用名称' : '固件名称';
        },
        uploadLabel() {
            return this.isApp ? '应用上传' : '固件上传';
        },
        accept() {
            return this.isApp ? '.apk,.ipa,.APK,.IPA' : '*';
        }
    },
    mounted() {
        this.form.rules = this.isApp ? 'apk' : 'basic';
    },
    methods: {
        initShowName(name) {
            this.$refs.file.clearValidate();
        },
        cancelScan() {
            this.$emit('update:showCreateDialog', false);
        },
        async confirmScan(formName) {
            if (['apk', 'ipa'].includes(this.fileType) && !this.fileInfo.appName) {
                return this.$message.warning('请输入应用名称');
            }
            this.isConfirm = true;
            await this.$refs[formName].validate();
            try {
                const { name, file, rules } = this.form;
                const engineCustom = this.isApp ? getSuffixByFileName(file.fileName) : rules;
                let params = {
                    fileName: file.fileName,
                    fileMd5: file.fileMd5,
                    engineCustom,
                    fileSha1: file.fileSha1,
                    fileSha256: file.fileSha256,
                    fileUrl: file.fileUrl
                }
                if (this.isApp) {
                    params.name = this.fileInfo.appName || this.fileInfo.fileName;
                    if (['apk', 'ipa'].includes(this.fileType) && this.fileInfo.appIcon) {
                        const key = `security-scan-web/appicon/${
                            new Date().getTime()
                        }/${
                            this.fileInfo.appName
                        }.png`;
                        const uploader = new Uploader();
                        await uploader.uploadLargeFile({
                            blob: base64toBlob(this.fileInfo.appIcon),
                            key,
                            fileName: `${this.fileInfo.appName}.png`,
                            onProgress: () => {},
                            onPartFileResolve: () => {}
                        });
                        params.fileInfo = JSON.stringify({
                            ...this.fileInfo,
                            appIcon: 'https://sec-scan-faw-vk.cdn.bcebos.com/' + key
                        });
                    } else {
                        params.fileInfo = JSON.stringify(this.fileInfo);
                    }
                } else {
                    params.name = name;
                }

                const formatTaskType = () => {
                    if (this.isApp) {
                        if (this.fileType === 'apk') {
                            return 'app';
                        } else {
                            return this.fileType;
                        }
                    } else {
                        return 'firmware';
                    }
                };

                const payload = {
                    data: params,
                    params: [formatTaskType()]
                };
                this.createTask(payload);
            } catch (_) {
                console.log(_);
                this.$message.error('创建失败，请检查后重试');
                this.isConfirm = false;
            }
        },
        async createTask(params) {
            try {
                await createSecurityTask(params);
                this.$message({
                    type: 'success',
                    message: '创建成功',
                });
                this.$emit('update:showCreateDialog', false);
                this.$emit('updateData');
            } catch (err) {
                this.$refs.upload.cancelUploadedFile();
                this.$message.error('创建失败，请重试!');
                console.log(err);
            } finally {
                this.isConfirm = false;
            }
        },
        async parseApp(file, extension) {
            const fileInfo = {};
            if (['apk', 'ipa'].includes(extension)) {
                const appInfoParser = new AppInfoParser(file);
                this.parsing = true;
                try {
                    const result = await appInfoParser.parse();
                    this.parsing = false;
                    if (extension === 'ipa') {
                        fileInfo.appIcon = result.icon;
                        fileInfo.appName = result.CFBundleDisplayName;
                        fileInfo.appPackageName = result.CFBundleIdentifier;
                        fileInfo.appVersion = result.CFBundleVersion;
                    } else {
                        fileInfo.appIcon = result.icon;
                        fileInfo.appName = result.application.label[0] || '';
                        fileInfo.appPackageName = result.package;
                        fileInfo.appVersion = result.versionName;
                    }
                } catch (error) {
                    this.$message.warning('app解析失败，请上传正确的apk、ipa文件');
                }
            }
            fileInfo.fileSize = file.size;
            fileInfo.fileName = file.name;
            this.fileInfo = fileInfo;
        },
        conver
    },
    watch: {
        'form.file.blob'(to) {
            if (to && !this.fileInfo) {
                const extension  = to.name.split('.').pop().toLowerCase();
                if (this.isApp) {
                    this.parseApp(to, extension);
                }
            }
        },
        'form.file'(to) {
            console.log(to);
            if (!to.blob) {
                this.fileInfo = null;
            }
        }
    }
}
</script>
<style lang="scss">
    .firmware-create-dialog {
        font-size: 12px;
        color: #333333;
        // form
        .firmware-create-form {
            font-size: 12px;
            color: #333333;
            .el-input {
                width: 240px;
            }
        }
        // dialog
        .el-dialog__header {
            color: #333333;
            font-size: 14px;
            padding: 19px 20px 19px 30px;
            border-bottom: 1px solid rgba(228, 228, 229, .6);
        }
        .el-dialog__body {
            padding: 36px;
            box-sizing: border-box;
            color: #333333;
            font-size: 12px;
            line-height: 24px;
        }
        .el-dialog__footer {
            padding: 0 20px 30px;
            text-align: center;
            .dialog-footer {
                padding-top: 0;
            }
            .el-button {
                min-width: 104px;
            }
        }
    }
</style>