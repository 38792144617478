/**
 * @file:
 * @author: sunchao(sunchao15@baidu.com)
 * @Date: 2019-08-19 18:26:54
 * @LastEditors: sunchao
 * @LastEditTime: 2020-05-08 15:06:45
 */

export default {
    methods: {
        fetchDataByURL(url) {
            fetch(url).then(res => res.blob().then(blob => {
                let a = document.createElement('a');
                let newurl = window.URL.createObjectURL(blob);
                a.href = newurl;
                a.download = url && url.replace(/^.+\//, '').replace(/\?.*/, '');
                a.click();
                window.URL.revokeObjectURL(url);
            }));
        },
        fetchDataByBlob(blob, url) {
            let a = document.createElement('a');
            let newurl = window.URL.createObjectURL(blob);
            a.href = newurl;
            a.download = url && url.replace(/^.+\//, '');
            a.click();
            window.URL.revokeObjectURL(url);
        },
        fetchCsvFile(url) {
            return fetch(url).then(res => res.blob().then(blob => {
                let a = document.createElement('a');
                let newurl = window.URL.createObjectURL(blob);
                a.href = newurl;
                a.download = url && url.replace(/^.+\//, '');
                a.download += '.csv';
                a.click();
                window.URL.revokeObjectURL(url);
            }));
        }
    }
};
