/**
 * @file: 封装axios方法, 默认使用post方法
 * @Author: sunchao(sunchao15@baidu.com)
 * @Date: 2019-08-06 12:08:01
 * @LastEditors: sunchao
 * @LastEditTime: 2020-05-27 15:20:25
 */

import Axios from 'axios';
import qs from 'qs';
import router from '../router/';

import { Message } from 'element-ui';
import {
    otaServer,
    mock,
    otaServerV2,
    securityV1
} from './env';

// 防止展示过多相同的消息弹窗
const messages = {};
function showMessage(message) {
    if (messages[message]) {
        return;
    }
    messages[message] = true;
    Message.error(message);
    setTimeout(() => {
        delete messages[message];
    }, 2000);
}

const axios = Axios.create();
// 拦截器 处理token失效
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {

    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    // 对响应数据做处理
    if (response.data.errno !== 0) {
        showMessage(response.data.msg);
    }

    return response;
}, error => {
    if (error.response.status === 403) {
        showMessage('登录超时');
        router.replace({ name: 'login' });
    } else if (String(error.response.status).startsWith(4)) {
        showMessage('http错误：请求错误');
    } else if (String(error.response.status).startsWith(5)) {
        showMessage('http错误：服务器错误');
    } else {
        showMessage('http错误：未知错误');
    }
    return Promise.reject(error);
});

export const pureAxios = url => axios.get(url); // 下载专用 不用封装

// 全局错误处理和局部错误处理 分裂化为两个函数
export const partAxios = async(urlFlag = 'normal', url, params) => {
    const baseUrl = urlFlag === 'v1' ? securityV1 : urlFlag === 'mock' ? mock : otaServer;
    url = baseUrl + url;
    try {
        const response = await axios.get(url, params);
        return response;
    }
    catch (error) {
        throw new Error(error);
    }
};

export const securityAxiosV1 = async (method, uri, options) => {
    const baseUrl = securityV1;
    const $route = router.history.current;
    let {
        query,
        data = {},
        params
    } = options;
    params = params ? params : [$route.name];
    let url = baseUrl + (uri ? `${uri}` : '');
    url += query ? `?${qs.stringify(query)}` : '';
    if (params && Object.keys(params).length > 0) {
        let count = 0;
        url = url.replace(/\{.*?\}/g, () => params[count++]);
    }
    let result = {};
    const response = await axios[method](url, data);
    const code = response.data.errno;
    if (code !== 0) {
        return Promise.reject(response.data);
    } else {
        result = response.data.data;
    }
    return result;
};

export const jsonRequestV2 = async(urlFlag = '', url, params) => {
    const baseUrl = urlFlag === 'mock' ? mock : otaServerV2;
    url = baseUrl + url;
    try {
        const response = await axios.post(url, params);
        const resData = response.data;
        if (resData.errno === 0) {
            return resData.data;
        }
        else {
            if (resData.errno === 1003) { // 升级包错误处理
                throw new Error(1003);
            }

            if (resData.errno === 105) { // 复用任务失败 暂时处理
                throw new Error('复用任务失败');
            }

            if (resData.errno === -102) { // 字段缺失
                throw new Error('字段缺失');
            }

            throw new Error(resData.errno);
            // 错误捕获不了 怎么办？
            // 局部错误处理 直接捕获状态码即可
            // 如1003
            // catch (error) {
            //     console.log(error)
            //     if (error.message === '1003') {
            //     }
            // }
        }
    }
    catch (error) {
        if (error.message === 'Request failed with status code 500') {
            Message.error('网络错误, 请稍后重试');
        }

        if (error.response) {
            throw new Error(error.response.data.errno);
        }

        throw new Error(error);
    }
};
